import {ReactNode, useEffect} from 'react';
import cn from 'classnames';

import {addPaddingBtmOnFixedBtm} from '@zzng/common/markup/addPaddingBtmOnFixedBtm';
import {addPaddingOnGroupEmpty} from '@zzng/common/markup/addPaddingOnGroupEmpty';

import {isRequiredIOSHeaderIssueTalkVersion} from '@/utils/agent';

type Props = {
  className?: string;
  children: ReactNode;
};

const MainContent = ({className, children}: Props) => {
  useEffect(() => {
    addPaddingBtmOnFixedBtm();
    addPaddingOnGroupEmpty();
  }, []);

  return (
    <div
      id="mainContent"
      className={cn('doc-main', {'ios-header-version': isRequiredIOSHeaderIssueTalkVersion})}
      role="main"
    >
      <div className={cn('content-article', className)} role="article">
        {children}
      </div>
    </div>
  );
};

export default MainContent;
