// 서버에서 내려주는 에러 코드
export enum ServerErrorCode {
  INVALID_PHONE_NUMBER = 'invalid-phone-number',
  INVALID_LOGIN_STATUS = 'invalid-login-status',
  INVALID_UNIVERSITY_DEGREE = 'invalid-university-degree',
  INVALID_STUDENT_IDENTIFIER = 'invalid-student-identifier',
  INVALID_STUDENT_CREDENTIAL = 'invalid-student-credential',
  INVALID_CREDENTIAL_CATEGORY = 'invalid-credential-category',
  INVALID_AUTH = 'invalid-auth',
  ABUSED_USER = 'abused-user',
  UNDER_MAINTENANCE = 'under-maintenance',
  UNDER_MAINTENANCE_UNIVERSITY = 'under-maintenance-university',
  INVALID_DEVICE = 'invalid-device',
  SIGN_FAILED = 'sign-failed',
  DIGITAL_CARD_ERROR = 'digital-card-error',
  CIRCUIT_OPEN = 'circuit-open',
  EXCESSIVE_REQUESTS = 'excessive-requests'
}
