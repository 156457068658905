import {useEffect, useState} from 'react';

import {DARK_MODE_MEDIA_QUERY} from '@zzng/common/constants/mediaQuery';

const useDarkMode = () => {
  const darkModeMediaQuery = window.matchMedia(DARK_MODE_MEDIA_QUERY);
  const [isDarkMode, setIsDarkMode] = useState(darkModeMediaQuery.matches);

  useEffect(() => {
    const onDarkModeChange = (e: MediaQueryListEvent) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener('change', onDarkModeChange);
    return () => darkModeMediaQuery.removeEventListener('change', onDarkModeChange);
  }, []);

  return {isDarkMode};
};

export default useDarkMode;
