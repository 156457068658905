import {ReactNode} from 'react';

import Button from '@/components/Popup/Layout/Button';
import ButtonGroup from '@/components/Popup/Layout/ButtonGroup';
import Description from '@/components/Popup/Layout/Description';
import Title from '@/components/Popup/Layout/Title';

interface Props {
  children: ReactNode;
}

const Wrapper = ({children}: Props) => {
  return (
    <>
      <div className="dimmed_g type2"></div>
      <div className="popup_g">
        <div className="popup_body">{children}</div>
      </div>
    </>
  );
};

export default {Wrapper, Title, Description, ButtonGroup, Button};
