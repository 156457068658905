import {useEffect} from 'react';

import Popup from '@/components/Popup/Layout';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {usePopupStore} from '@/store/popup';

const MaintenanceUniversityPopup = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_UNIVERSITY_SEARCH});

  const {closePopup} = usePopupStore();

  const onClickConfirm = () => {
    trackEvent({action: TIARA_ACTION.CONFIRM_CLICK});
    closePopup();
  };

  useHistoryBackAOS(onClickConfirm);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.IN_MAINTENANCE_UNIVERSITY_VIEW});
  }, []);

  return (
    <Popup.Wrapper>
      <Popup.Title>점검 중인 학교입니다</Popup.Title>
      <Popup.Description>점검이 끝난 후 톡학생증 발급이 가능하니 나중에 다시 시도해 주세요.</Popup.Description>
      <Popup.ButtonGroup>
        <Popup.Button value="확인" onClick={onClickConfirm} />
      </Popup.ButtonGroup>
    </Popup.Wrapper>
  );
};

export default MaintenanceUniversityPopup;
