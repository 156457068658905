import {ReactNode, useEffect} from 'react';
import {SmoothieLayer} from '@kakao/smoothie';

import {useStatusBarDimmedColor} from '@/hooks/useStatusBarDimmedColor';
import {useHalfviewStore} from '@/store/halfview';

type Props = {
  classnames: string;
  children: ReactNode;
  onExited: () => void;
  onEntered?: () => void;
};

const Wrapper = ({classnames, children, onExited, onEntered}: Props) => {
  const {open, setOpen} = useHalfviewStore();

  useStatusBarDimmedColor(open);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <SmoothieLayer.Wrapper open={open} className={classnames} onExited={onExited} onEntered={onEntered}>
      {children}
    </SmoothieLayer.Wrapper>
  );
};

interface HeaderProps {
  children?: ReactNode;
  className?: string;
  onClickPrev?: () => void;
}

const Header = ({children, className, onClickPrev}: HeaderProps) => (
  <SmoothieLayer.Header className={className} onClickPrev={onClickPrev}>
    {children}
  </SmoothieLayer.Header>
);

interface BodyProps {
  children: ReactNode;
  className?: string;
}

const Body = ({children, className}: BodyProps) => (
  <SmoothieLayer.Body className={className}>{children}</SmoothieLayer.Body>
);

interface FooterProps {
  children: ReactNode;
}

const Footer = ({children}: FooterProps) => <SmoothieLayer.Footer>{children}</SmoothieLayer.Footer>;

export default {
  Wrapper,
  Header,
  Body,
  Footer
};
