import {useEffect} from 'react';

export const useHistoryBackAOS = (fn: () => void, deps: any[] = []) => {
  useEffect(() => {
    const cached = window.historyBack;
    window.historyBack = fn;

    return () => {
      window.historyBack = cached;
    };
  }, deps);
};
