import {useEffect} from 'react';

import {type LottieId, createLottie} from '@zzng/common/markup/createLottie';

type Props = {
  id: LottieId;
};

const Lottie = ({id}: Props) => {
  useEffect(() => {
    createLottie(id);
  }, []);

  return <div id={id} className="visual_animation" />;
};

export default Lottie;
