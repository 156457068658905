import {ReactNode} from 'react';
import cn from 'classnames';

import {STATUS_BAR_COLOR} from '@/constants/statusBarColor';
import {useStatusBarColor} from '@/hooks/useStatusBarColor';

type Props = {
  className: string;
  children: ReactNode;
  statusBarColor?: {dark: string; light: string};
};

const Layout = ({className, children, statusBarColor = STATUS_BAR_COLOR.default}: Props) => {
  useStatusBarColor({color: statusBarColor});

  return <div className={cn('container-doc', className)}>{children}</div>;
};

export default Layout;
