import cn from 'classnames';

import {closeWebView} from '@zzng/common/scheme/web';

import {isRequiredIOSHeaderIssueTalkVersion} from '@/utils/agent';

export enum HEADER_BUTTON_TYPE {
  CLOSE,
  BACK
}

interface Props {
  title: string;
  closeType?: HEADER_BUTTON_TYPE;
  onClickBack?: () => void;
  onClickClose?: () => void;
  showTitle?: boolean;
  onClickShare?: () => void;
}

const Header = ({
  title,
  closeType = HEADER_BUTTON_TYPE.CLOSE,
  onClickBack = () => history.back(),
  onClickClose = closeWebView,
  showTitle = false,
  onClickShare
}: Props) => {
  if (isRequiredIOSHeaderIssueTalkVersion) return null;

  return (
    <div className="doc-header" role="banner">
      <div className="inner_g">
        {closeType === HEADER_BUTTON_TYPE.CLOSE ? (
          <button type="button" className="btn_close btn_left" onClick={onClickClose}>
            <span className="icon icon_close">페이지 닫기</span>
          </button>
        ) : (
          <button type="button" className="btn_prev" onClick={onClickBack}>
            <span className="icon icon_prev">뒤로 가기</span>
          </button>
        )}
        <h1 className={cn('tit_header', {show: showTitle})}>{title}</h1>
        {onClickShare && (
          <button type="button" className="btn_share" onClick={onClickShare}>
            <span className="icon icon_ktalk">카카오톡으로 공유하기</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
