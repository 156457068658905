import {Suspense, useLayoutEffect} from 'react';
import {Route, Routes} from 'react-router-dom';

import {initClientInterface} from '@zzng/common/scheme/digitalcard';
import {isLocal} from '@zzng/common/utils/common';
import {getHistory} from '@zzng/common/utils/history';

import Buggerking from '@/components/Buggerking';
import OutletPopup from '@/components/OutletPopup';
import Popup from '@/components/Popup';
import {ROUTES, ROUTES_PREFIX} from '@/constants/routes';
import CustomRouter from '@/hoc/CustomRouter';
import CircuitOpen from '@/pages/error/CircuitOpen';
import ErrorOutlet from '@/pages/error/components/ErrorOutlet';
import DigitalCardError from '@/pages/error/DigitalCardError';
import ErrorTest from '@/pages/error/ErrorTest';
import ExcessiveRequests from '@/pages/error/ExcessiveRequests';
import InvalidCredentialCategory from '@/pages/error/InvalidCredentialCategory';
import InvalidDevice from '@/pages/error/InvalidDevice';
import InvalidPhoneNumber from '@/pages/error/InvalidPhoneNumber';
import InvalidStudent from '@/pages/error/InvalidStudent';
import InvalidUniversityDegree from '@/pages/error/InvalidUniversityDegree';
import InvalidVersion from '@/pages/error/InvalidVersion';
import LoginError from '@/pages/error/LoginError';
import NetworkError from '@/pages/error/NetworkError';
import ServerError from '@/pages/error/ServerError';
import ServiceMaintenance from '@/pages/error/ServiceMaintenance';
import SignFailed from '@/pages/error/SignFailed';
import UnderMaintenanceUniversity from '@/pages/error/UnderMaintenanceUniversity';
import Intro from '@/pages/intro';
import Issue from '@/pages/issue';
import Agreement from '@/pages/issue/agreement';
import AgreementHalfviewOutlet from '@/pages/issue/agreement/components/AgreementHalfviewOutlet';
import KakaoCollectUseTerms from '@/pages/issue/agreement/KakaoCollectUseTerms';
import KakaoTerms from '@/pages/issue/agreement/KakaoTerms';
import MarketingUsage from '@/pages/issue/agreement/MarketingUsage';
import PublisherTerms from '@/pages/issue/agreement/PublisherTerms';
import Complete from '@/pages/issue/complete';
import Confirm from '@/pages/issue/confirm';
import CertificateIssueAgreement from '@/pages/issue/confirm/CertificateIssueAgreement';
import DegreeHalfview from '@/pages/issue/degree';
import Progress from '@/pages/issue/progress';
import UniversitySearchHalfview from '@/pages/issue/universitySearch';
import NotFound from '@/pages/notFound';
import OnlyMobile from '@/pages/onlyMobile';
import UniversityList from '@/pages/universityList';
import InAppPrivateRoutes from '@/routes/InAppPrivateRoutes';
import {useUniversity} from '@/store/university';
import {globalErrorHandler} from '@/utils/globalErrorHandler';

initClientInterface();
globalErrorHandler();
const history = getHistory();

const App = () => {
  const {initUniversity} = useUniversity();
  // NOTE: 전용웹뷰가 여러 개 존재하여 localStorage에 이전 데이터가 남아 있을 수 있어, 발급 페이지에 첫 진입했을때는 입력한 대학 데이터 초기화
  // https://github.daumkakao.com/zzng/digital-card-front/pull/412
  useLayoutEffect(() => {
    if (window.location.pathname === ROUTES.ISSUE || window.location.pathname === ROUTES.ROOT) initUniversity();
  }, []);

  return (
    <CustomRouter history={history}>
      <Suspense>
        <Routes>
          <Route path={ROUTES.ROOT} element={<Intro />}>
            <Route path={ROUTES.ROOT_POPUP} element={<OutletPopup />} />
          </Route>
          <Route path={ROUTES.INVALID_VERSION} element={<InvalidVersion />} />
          <Route path={ROUTES.ONLY_MOBILE} element={<OnlyMobile />} />
          <Route element={<InAppPrivateRoutes />}>
            <Route path={ROUTES.ISSUE} element={<Issue />}>
              <Route path={ROUTES.ISSUE_UNIVERSITY_SEARCH} element={<UniversitySearchHalfview />} />
              <Route path={ROUTES.ISSUE_DEGREE} element={<DegreeHalfview />} />
              <Route element={<AgreementHalfviewOutlet />}>
                <Route path={ROUTES.ISSUE_AGREEMENT} element={<Agreement />} />
                <Route path={ROUTES.ISSUE_KAKAO_COLLECT_USE_TERMS} element={<KakaoCollectUseTerms />} />
                <Route path={ROUTES.ISSUE_KAKAO_TERMS} element={<KakaoTerms />} />
                <Route path={ROUTES.ISSUE_PUBLISHER_TERMS} element={<PublisherTerms />} />
                <Route path={ROUTES.ISSUE_MARKETING_USAGE_TERMS} element={<MarketingUsage />} />
              </Route>
            </Route>
            <Route path={ROUTES.ISSUE_PROGRESS} element={<Progress />} />
            <Route path={ROUTES.ISSUE_CONFIRM} element={<Confirm />}>
              <Route path={ROUTES.ISSUE_CERTIFICATE_ISSUE_AGREEMENT} element={<CertificateIssueAgreement />} />
            </Route>
            <Route path={ROUTES.ISSUE_COMPLETE} element={<Complete />} />
            <Route path={ROUTES_PREFIX.ERROR} element={<ErrorOutlet />}>
              <Route path={ROUTES.NETWORK_ERROR} element={<NetworkError />} />
              <Route path={ROUTES.LOGIN_ERROR} element={<LoginError />} />
              <Route path={ROUTES.SERVICE_MAINTENANCE_ERROR} element={<ServiceMaintenance />} />
              <Route path={ROUTES.UNDER_MAINTENANCE_UNIVERSITY} element={<UnderMaintenanceUniversity />} />
              <Route path={ROUTES.INVALID_PHONE_NUMBER} element={<InvalidPhoneNumber />} />
              <Route path={ROUTES.INVALID_DEVICE} element={<InvalidDevice />} />
              <Route path={ROUTES.SERVER_ERROR} element={<ServerError />} />
              <Route path={ROUTES.INVALID_UNIVERSITY_DEGREE} element={<InvalidUniversityDegree />} />
              <Route path={ROUTES.INVALID_STUDENT} element={<InvalidStudent />} />
              <Route path={ROUTES.INVALID_CREDENTIAL_CATEGORY} element={<InvalidCredentialCategory />} />
              <Route path={ROUTES.SIGN_FAILED} element={<SignFailed />} />
              <Route path={ROUTES.DIGITAL_CARD_ERROR} element={<DigitalCardError />} />
              <Route path={ROUTES.CIRCUIT_OPEN} element={<CircuitOpen />} />
              <Route path={ROUTES.EXCESSIVE_REQUESTS} element={<ExcessiveRequests />} />
            </Route>
          </Route>
          <Route path={ROUTES.UNIVERSITY_LIST} element={<UniversityList />} />
          <Route path="*" element={<NotFound />} />
          {isLocal && <Route path="/error-test" element={<ErrorTest />} />}
        </Routes>
        <Popup />
        <Buggerking />
      </Suspense>
    </CustomRouter>
  );
};

export default App;
