import {useEffect} from 'react';

import {isKakaoTalk} from '@zzng/common/utils/agent';

export const useLayoutClass = () => {
  useEffect(() => {
    // 인트로 > 발급하기 버튼 때문에 텍스트가 가려지는 이슈, 그리고 혜택 페이지 > 버튼이 짤리는 현상이 있어 body.layout 클래스를 제거한다.
    // https://jira.daumkakao.com/browse/STUDENTID-932
    document.body.classList.replace('layout', 'layout_type2');
    if (!isKakaoTalk) {
      // 사파리 브라우저 하단 주소창 영역에 이슈로 버튼이 가려지는 경우가 있어 추가한 클래스
      document.body.classList.add('layout_fill');
    }
  }, []);
};
