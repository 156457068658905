import {useEffect} from 'react';

import Popup from '@/components/Popup/Layout';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import {usePopupStore} from '@/store/popup';
import {useUniversity} from '@/store/university';

const IssueStopPopup = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_STOP});
  const {closePopup, confirmBtnClick} = usePopupStore();
  const {identifier} = useUniversity();

  const onCancelClick = () => {
    trackEvent({action: TIARA_ACTION.CANCEL_CLICK});
    closePopup();
  };

  const onConfirmClick = () => {
    trackEvent({action: TIARA_ACTION.CONFIRM_CLICK});
    confirmBtnClick();
  };

  useEffect(() => {
    trackPage({
      action: TIARA_ACTION.ISSUE_STOP_VIEW,
      customProps: {identifier: String(identifier)}
    });

    return () => {
      trackEvent({action: TIARA_ACTION.CANCEL_CLICK});
    };
  }, []);

  return (
    <Popup.Wrapper>
      <Popup.Title>발급을 그만둘까요?</Popup.Title>
      <Popup.Description>지금 발급을 그만두면 처음부터 다시 진행해야합니다.</Popup.Description>
      <Popup.ButtonGroup>
        <Popup.Button value="취소" onClick={onCancelClick} />
        <Popup.Button value="확인" onClick={onConfirmClick} />
      </Popup.ButtonGroup>
    </Popup.Wrapper>
  );
};

export default IssueStopPopup;
