import {useEffect} from 'react';

const SCRIPT_ID = 'kakao-sdk-script';

/** 카카오 SDK 임베드 훅 */
const useKakaoSDK = () => {
  useEffect(() => {
    if (document.getElementById(SCRIPT_ID)) {
      return;
    }
    const kakaoShareSdkScript = document.createElement('script');
    kakaoShareSdkScript.id = SCRIPT_ID;
    kakaoShareSdkScript.src = KAKAO_SDK;
    document.head.appendChild(kakaoShareSdkScript);
  }, []);
};

export default useKakaoSDK;
