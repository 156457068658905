export const ROUTES_PREFIX = {
  ISSUE: '/issue',
  ERROR: '/error',
  AGREEMENT: '/agreement',
  POPUP: '/popup',
  CONFIRM: '/confirm'
} as const;

export const ROUTES = {
  ROOT: '/',
  ROOT_POPUP: `${ROUTES_PREFIX.POPUP}`,
  ISSUE_UNIVERSITY_SEARCH: `${ROUTES_PREFIX.ISSUE}/universitySearch`,
  ISSUE_DEGREE: `${ROUTES_PREFIX.ISSUE}/degree`,
  ISSUE_AGREEMENT: `${ROUTES_PREFIX.ISSUE}${ROUTES_PREFIX.AGREEMENT}`,
  ISSUE_KAKAO_TERMS: `${ROUTES_PREFIX.ISSUE}${ROUTES_PREFIX.AGREEMENT}/kakao`,
  ISSUE_KAKAO_COLLECT_USE_TERMS: `${ROUTES_PREFIX.ISSUE}${ROUTES_PREFIX.AGREEMENT}/kakaoCollectUse`,
  ISSUE_PUBLISHER_TERMS: `${ROUTES_PREFIX.ISSUE}${ROUTES_PREFIX.AGREEMENT}/publisher`,
  ISSUE_MARKETING_USAGE_TERMS: `${ROUTES_PREFIX.ISSUE}${ROUTES_PREFIX.AGREEMENT}/marketingUsage`,
  INVALID_VERSION: '/invalid-version',
  ISSUE: ROUTES_PREFIX.ISSUE,
  ISSUE_PROGRESS: `${ROUTES_PREFIX.ISSUE}/progress`,
  ISSUE_CONFIRM: `${ROUTES_PREFIX.ISSUE}${ROUTES_PREFIX.CONFIRM}`,
  ISSUE_CERTIFICATE_ISSUE_AGREEMENT: `${ROUTES_PREFIX.ISSUE}${ROUTES_PREFIX.CONFIRM}/certificateIssueAgreement`,
  ISSUE_COMPLETE: `${ROUTES_PREFIX.ISSUE}/complete`,
  NETWORK_ERROR: `${ROUTES_PREFIX.ERROR}/network`,
  LOGIN_ERROR: `${ROUTES_PREFIX.ERROR}/login`,
  SERVICE_MAINTENANCE_ERROR: `${ROUTES_PREFIX.ERROR}/service-maintenance`,
  UNDER_MAINTENANCE_UNIVERSITY: `${ROUTES_PREFIX.ERROR}/under-maintenance-university`,
  INVALID_UNIVERSITY_DEGREE: `${ROUTES_PREFIX.ERROR}/invalid-university-degree`,
  INVALID_STUDENT: `${ROUTES_PREFIX.ERROR}/invalid-student`,
  INVALID_CREDENTIAL_CATEGORY: `${ROUTES_PREFIX.ERROR}/invalid-credential-category`,
  SIGN_FAILED: `${ROUTES_PREFIX.ERROR}/sign-failed`,
  DIGITAL_CARD_ERROR: `${ROUTES_PREFIX.ERROR}/digital-card-error`,
  CIRCUIT_OPEN: `${ROUTES_PREFIX.ERROR}/circuit-open`,
  INVALID_PHONE_NUMBER: `${ROUTES_PREFIX.ERROR}/invalid-phone-number`,
  INVALID_DEVICE: `${ROUTES_PREFIX.ERROR}/invalid-device`,
  SERVER_ERROR: `${ROUTES_PREFIX.ERROR}/server`,
  NOT_FOUND: `${ROUTES_PREFIX.ERROR}/not-found`,
  EXCESSIVE_REQUESTS: `${ROUTES_PREFIX.ERROR}/excessive-requests`,
  ONLY_MOBILE: '/only-mobile',
  POPUP: ROUTES_PREFIX.POPUP,
  UNIVERSITY_LIST: '/university-list'
} as const;
