import {forwardRef, useImperativeHandle, useRef} from 'react';
import cn from 'classnames';

export type SelectComponent = {
  focus: () => void;
  focusOut: () => void;
};

type Props = {
  onClick: () => void;
  placeholder: string;
  selectedValue?: string;
};

const Select = forwardRef(({onClick, placeholder, selectedValue}: Props, ref) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (!wrapperRef.current || !selectRef.current) return;

      wrapperRef.current.classList.add('focus');
      selectRef.current.ariaPressed = 'true';
    },
    focusOut: () => {
      if (!wrapperRef.current || !selectRef.current) return;

      wrapperRef.current.classList.remove('focus');
      selectRef.current.ariaPressed = 'false';
    }
  }));

  return (
    <div ref={wrapperRef} className={cn('item_g item_select', {selected: !!selectedValue})}>
      <button ref={selectRef} type="button" className="btn_select" aria-haspopup="dialog" onClick={onClick}>
        <span className="txt_placeholder">{placeholder}</span>
        <span className="txt_selected" role="text">
          <span className="screen_out">현재 선택된 옵션 : </span>
          {selectedValue || <span className="screen_out">없음</span>}
        </span>
        <span className="icon icon_arr_down" />
      </button>
    </div>
  );
});

Select.displayName = 'Select';

export default Select;
