import {useEffect} from 'react';

import Popup from '@/components/Popup/Layout';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import useTiara from '@/hooks/useTiara';
import {usePopupStore} from '@/store/popup';

const IssueProgressPopup = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.ISSUE_PROGRESS});
  const {closePopup} = usePopupStore();

  const onClick = () => {
    trackEvent({action: TIARA_ACTION.CONFIRM_CLICK});
    closePopup();
  };

  useEffect(() => {
    trackPage({action: TIARA_ACTION.ISSUE_PROGRESS_CANT_CLOSE_VIEW});

    return () => {
      trackEvent({action: TIARA_ACTION.CONFIRM_CLICK});
    };
  }, []);

  return (
    <Popup.Wrapper>
      <Popup.Title>현재 학적 정보를 조회하는 중이에요.</Popup.Title>
      <Popup.Description>
        <>
          학적 정보를 조회하는 중에는 화면을 닫을 수 없어요.
          <br />
          조회가 완료 될 때까지 잠시만 기다려 주세요.
        </>
      </Popup.Description>
      <Popup.ButtonGroup>
        <Popup.Button value="확인" onClick={onClick} />
      </Popup.ButtonGroup>
    </Popup.Wrapper>
  );
};

export default IssueProgressPopup;
