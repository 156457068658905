import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  startTransition,
  useEffect,
  useRef,
  useState
} from 'react';
import cn from 'classnames';

import {isIOS} from '@zzng/common/utils/agent';

import FixedConfirmBtm from '@/components/Input/FixedConfirmBtm';

type Props = {
  input: string;
  type: HTMLInputElement['type'];
  htmlFor: HTMLLabelElement['htmlFor'];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickClear: () => void;
  label: string;
  placeholder: string;
  showFixedConfirmBtm: boolean;
  errorMessage?: string;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({input, type, htmlFor, onChange, onClickClear, label, placeholder, showFixedConfirmBtm, errorMessage}, ref) => {
    const _inputRef = useRef<HTMLInputElement>(null);
    const inputRef = (ref as MutableRefObject<HTMLInputElement>) || _inputRef;
    const bufferedInputRef = useRef<HTMLInputElement>(null);
    const [opened, setOpened] = useState(false);

    // 에러 여부 및 에러메시지
    const isError = !!errorMessage;

    const inputClear = () => {
      inputRef.current?.focus();

      if (isIOS) {
        bufferedInputRef.current?.focus();

        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    };

    const onFocus = () => {
      setOpened(true);
    };

    const onBlur = () => {
      startTransition(() => {
        setOpened(false);
      });
    };

    const onClick = () => {
      onClickClear();
      inputClear();
    };

    const blurInput = () => {
      inputRef.current?.blur();
      setOpened(false);
    };

    /** # 다음 keyUp 이벤트 시 인풋 blur 해야하는지 여부
     * IME로 인해, key event handler 에서 blur 시
     * 조합형 입력이 중복되는 이슈가 있어
     * blur 시점을 조절하기 위해 사용합니다.
     * Enter 키 입력 시 뒤따라오는 Unidentified 키까지
     * key 이벤트가 총 2번 발생하므로,
     * 2번째 이벤트에서 blur 되도록 합니다.
     * */
    const blurOnNextKeyUp = useRef<boolean>(false);
    const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
      if (blurOnNextKeyUp.current) {
        blurOnNextKeyUp.current = false;
        blurInput();
        return;
      }

      if (e.key === 'Enter') {
        if (e.nativeEvent.isComposing) {
          blurOnNextKeyUp.current = true;
          return;
        }
        blurInput();
      }
    };

    const onTouchmove = (e: TouchEvent) => {
      if (!opened) return;

      const y = e.touches[0].clientY;
      const viewportHeight = window.visualViewport?.height || 0;

      if (inputRef.current === e.target) {
        e.preventDefault();

        // 입력 엘리먼트를 누른채 키보드 영역까지 스와이프하면 스크롤이 가능하기 때문에
        // 스와이핑하고 있는 포지션y가 키보드 보다 클 경우 키보드를 닫는다.
        if (isIOS && y > viewportHeight) {
          inputRef.current?.blur();
        }
      } else {
        inputRef.current?.blur();
      }
    };

    useEffect(() => {
      document.addEventListener('touchmove', onTouchmove, {passive: false});

      return () => {
        document.removeEventListener('touchmove', onTouchmove);
      };
    }, [opened]);

    return (
      <>
        <div className={cn('item_g item_text', {focus: opened, error: isError})} onFocus={onFocus} onBlur={onBlur}>
          <label htmlFor={htmlFor} className="lab_g">
            {label}
          </label>
          <input ref={bufferedInputRef} className="hidden-input" />
          <input
            ref={inputRef}
            type={type}
            id={htmlFor}
            className="inp_g"
            placeholder={placeholder}
            onChange={onChange}
            onKeyUp={onKeyUp}
            value={input}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
          />
          <button type="button" className={cn('btn_reset', {show: opened && !!input})} onClick={onClick}>
            <span className="icon icon_delete">입력된 내용 지우기</span>
          </button>
        </div>
        {isError && (
          <p className="error item_desc" role="alert">
            {errorMessage}
          </p>
        )}
        {!!opened && showFixedConfirmBtm && <FixedConfirmBtm disabled={!input || isError} inputRef={inputRef} />}
      </>
    );
  }
);

Input.displayName = 'Input';

export default Input;
