import {useEffect} from 'react';

import {init} from '@zzng/common/utils/buggerking';

const Buggerking = () => {
  useEffect(() => {
    init('.tit_header');
  }, []);

  return null;
};

export default Buggerking;
