export interface MobileStatusBarColor {
  dark: string;
  light: string;
}

export const STATUS_BAR_COLOR = {
  default: {
    dark: '#000000',
    light: '#ffffff'
  }
} satisfies Record<string, MobileStatusBarColor>;
