import {Navigate, useNavigate, useOutletContext} from 'react-router-dom';

import Popup from '@/components/Popup/Layout';
import {ROUTES} from '@/constants/routes';
import {TIARA_ACTION} from '@/constants/tiara';
import {useStatusBarDimmedColor} from '@/hooks/useStatusBarDimmedColor';
import useTiara from '@/hooks/useTiara';

export type OutletPopupType = {
  title: string;
  desc: string;
  yellowButton?: {value: string; onClick: () => void};
  grayButton?: {value: string; onClick: () => void};
  tiaraPageName?: string;
};

/**
 * 최초 진입시에는 안드로이드 백버튼 물리키(historyBack)를 트리거할 방법이 없어,
 * 이를 해결하기 위해 만들어진 컴포넌트(<Outelt /> 과 함께 사용한다.)
 * 예를 들어) 소개 페이지 진입 후 혜택을 클릭하면 팝업이 노출되고 안드로이드 백 버튼을 눌러 팝업이 닫히는 플로우
 */
const OutletPopup = () => {
  const navigate = useNavigate();
  const [outletPopup] = useOutletContext<[OutletPopupType]>();
  const {trackEvent} = useTiara();

  const onClickGrayButton = () => {
    if (outletPopup?.tiaraPageName) {
      trackEvent({pageName: outletPopup.tiaraPageName, action: TIARA_ACTION.CONFIRM_CLICK});
    }
    navigate(-1);
  };

  useStatusBarDimmedColor();

  if (!outletPopup) return <Navigate to={ROUTES.NOT_FOUND} />;

  return (
    <Popup.Wrapper>
      <Popup.Title>{outletPopup.title}</Popup.Title>
      <Popup.Description>{outletPopup.desc}</Popup.Description>
      <Popup.ButtonGroup>
        <Popup.Button
          value={outletPopup.grayButton?.value || '확인'}
          onClick={outletPopup.grayButton?.onClick || onClickGrayButton}
        />
        {outletPopup.yellowButton && (
          <Popup.Button
            value={outletPopup.yellowButton.value}
            color="yellow"
            onClick={outletPopup.yellowButton.onClick}
          />
        )}
      </Popup.ButtonGroup>
    </Popup.Wrapper>
  );
};

export default OutletPopup;
