import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
};

const ButtonGroup = ({children}: Props) => {
  return <div className="group_btn">{children}</div>;
};

export default ButtonGroup;
