import {ROUTES} from '@/constants/routes';

export const TIARA_PAGE_NAME = {
  ISSUE: '발급하기',
  ISSUE_UNIVERSITY_SEARCH: '발급학교검색',
  ISSUE_DGREE_SELECTION: '발급학위선택',
  UNIVERSITY_LIST: '발급가능학교목록',
  ISSUE_AGREEMENT: '발급이용약관동의',
  ISSUE_KAKAO_TERMS: '발급카카오동의',
  ISSUE_KAKAO_COLLECT_USE: '발급카카오개인정보수집이용동의',
  ISSUE_PUBLISHER_TERMS: '발급학사정보기관동의',
  MARKETING_USAGE_TERMS: '맞춤형서비스개인정보동의',
  ISSUE_CONFIRM: '발급최종정보확인',
  CERTIFICATE_ISSUE_AGREEMENT: '증명서발급동의',
  ISSUE_PROGRESS: '발급대기',
  ISSUE_STOP: '발급중지',
  ISSUE_COMPLETE: '발급완료',
  ISSUE_FAIL: '발급실패',
  SERVICE_INTRO: '서비스소개',
  BENEFIT: '혜택안내',
  ERROR: '에러'
} as const;

type TiaraPageName = (typeof TIARA_PAGE_NAME)[keyof typeof TIARA_PAGE_NAME];

export const TIARA_ACTION = {
  ISSUE_VIEW: '발급하기_보기',
  UNIVERSITY_SELECTION_CLICK: '학교선택_클릭',
  DGREE_SELECTION_CLICK: '학위선택_클릭',
  NEXT_CLICK: '다음_클릭',
  CLOSE_CLICK: '닫기_클릭',
  ISSUE_UNIVERSITY_SEARCH_VIEW: '발급학교검색_보기',
  UNIVERSITY_NAME_CLICK: '학교명_클릭',
  ISSUABLE_UNIVERSITY_VIEW_CLICK: '발급가능학교보기_클릭',
  ISSUE_DGREE_SELECTION_VIEW: '발급학위선택_보기',
  DEGREE_CLICK: '학위_클릭',
  ISSUE_AGREEMENT_VIEW: '발급이용약관동의_보기',
  ALL_AGREEMENT_CLICK: '전체동의_클릭',
  ALL_AGREEMENT_CANCEL_CLICK: '전체동의해제_클릭',
  REQUIRED_AGREEMENT_CLICK: '필수항목개별_클릭',
  REQUIRED_AGREEMENT_CANCEL_CLICK: '필수항목개별해제_클릭',
  OPTIONAL_AGREEMENT_CLICK: '선택항목개별_클릭',
  OPTIONAL_AGREEMENT_CANCEL_CLICK: '선택항목개별해제_클릭',
  DETAIL_VIEW_CLICK: '보기_클릭',
  SIGN_AND_ISSUE_CLICK: '인증후발급하기_클릭',
  ISSUE_KAKAO_TERMS_VIEW: '발급카카오동의_보기',
  ISSUE_KAKAO_COLLECT_USE_VIEW: '발급카카오개인정보수집이용동의_보기',
  BACK_CLICK: '뒤로가기_클릭',
  ISSUE_PUBLISHER_TERMS_VIEW: '발급하기_학사정보기관동의_보기',
  PUBLISHER_CLICK: '학사정보기관_클릭',
  MARKETING_USAGE_TERMS_VIEW: '맞춤형서비스개인정보동의_보기',
  ISSUE_CONFIRM_VIEW: '발급최종정보확인_보기',
  ISSUE_CONFIRM_CERTIFICATE_ISSUE_CLICK: '증명서발급_클릭',
  ISSUE_CONFIRM_CERTIFICATE_ISSUE_AGREEMENT_VIEW: '증명서발급동의_보기',
  ISSUE_OFFER_CLICK: '발급요청하기_클릭',
  ISSUE_PROGRESS_VIEW: '발급대기_보기',
  ISSUE_PROGRESS_NEED_ISSUE_VIEW: '발급대기_학적정보조회_보기',
  ISSUE_PROGRESS_CANT_CLOSE_VIEW: '발급대기_학적정보조회닫기불가_보기',
  ISSUE_STOP_VIEW: '발급중지_보기',
  CANCEL_CLICK: '취소_클릭',
  CONFIRM_CLICK: '확인_클릭',
  ISSUE_COMPLETE_VIEW: '발급완료_보기',
  CARD_VIEW_CLICK: '카드보기_클릭',
  ISSUE_FAIL_VIEW: '발급실패_보기',
  INVALID_STUDENT_VIEW: '학적조회불가_보기',
  INVALID_CREDENTIAL_CATEGORY_VIEW: '수료제적상태_보기',
  INVALID_UNIVERSITY_DEGREE_VIEW: '해당학교학위발급불가_보기',
  CUSTOMER_SERVICE_CLICK: '고객센터_클릭',
  SIGN_FAILED_VIEW: '인증미완료_보기',
  ERROR_SERVICE_VIEW: '에러_서비스오류_보기',
  ERROR_INVALID_PHONE_NUMBER_VIEW: '에러_해외사용자_보기',
  ERROR_INVALID_DEVICE_VIEW: '에러_주사용기기오류_보기',
  ERROR_INVALID_VERSION_VIEW: '에러_최신버전업데이트_보기',
  UPDATE_VERSION_CLICK: '최신버전업데이트하기_클릭',
  ERROR_NOT_FOUND_VIEW: '에러_페이지알수없음_보기',
  ERROR_LOGIN_VIEW: '에러_계정미로그인_보기',
  LOGIN_CONFIRM_CLICK: '계정미로그인확인_클릭',
  ERROR_SERVICE_MAINTENANCE_VIEW: '에러_서비스점겁_보기',
  ERROR_NETWORK_VIEW: '에러_네트워크에러_보기',
  NETWORK_RELOAD_CLICK: '네트워크에러새로고침_클릭',
  VIEW_SERVICE_INTRO: '서비스소개_보기',
  INTRO_ISSUE_CLICK: '지금발급받기_클릭',
  SHARE_KAKAOTALK_CLICK: '친구에게공유하기_클릭',
  SHARE_URL_CLICK: '주소복사하기_클릭',
  AVAILABLE_UNIVERTSITY_CLICK: '발급학교보기_클릭',
  PROPOSITION_CLICK: '제휴안내_클릭',
  CS_CLICK: '고객센터_클릭',
  EMOTICON_REQUEST_CLICK: '톡학생증으로이모티콘받기_클릭',
  KAKAOPAY_MEMBERSHIP_JOIN_CLICK: '카카오페이대학생멤버십가입_클릭',
  KAKAOTALK_SHARE_CLICK: '카카오톡공유_클릭',
  RETRY_POPUP_VIEW: '다시시도팝업_보기',
  PAGE_RELOAD_POPUP_VIEW: '페이지새로고침팝업_보기',
  RELOAD_CLICK: '새로고침_클릭',
  EMOTICON_PROMOTION_STOP_POPUP_VIEW: '이모티콘지급중단팝업_보기',
  NOT_LOGIN_POPUP_VIEW: '미로그인팝업_보기',
  LOGIN_CLICK: '로그인하기_클릭',
  ALREADY_REQUEST_EMOTICON_POPUP_VIEW: '이미이모티콘지급팝업_보기',
  SERVICE_ERROR_POPUP_VIEW: '서비스오류팝업_보기',
  EMOTICON_REQUEST_SUCCESS_POPUP_VIEW: '이모티콘지급완료팝업_보기',
  NEED_STUDENTID_POPUP_VIEW: '톡학생증필요팝업_보기',
  BENEFIT_CONTENT_CLICK: '혜택내용_클릭',
  BENEFIT_BUTTON_CLICK: '혜택버튼_클릭',
  VIEW_BENEFIT: '혜택안내_보기',
  ONLY_MOBILE: '에러_PC웹접근_보기',
  BENEFIT_ONGOING: '혜택안내_진행중혜택_보기',
  BENEFIT_CLOSED: '혜택안내_종료된혜택_보기',
  MORE_BENEFIT_CLICK: '더많은혜택보기_클릭',
  IN_MAINTENANCE_UNIVERSITY_VIEW: '점검중인학교_보기',
  UNIVERSITY_LIST_VIEW: '발급가능학교목록_보기'
} as const;

type TiaraAction = (typeof TIARA_ACTION)[keyof typeof TIARA_ACTION];

export const TIARA_AGREEMENT_HALFVIEW_MAP: {[key: string]: TiaraPageName} = {
  [ROUTES.ISSUE_AGREEMENT]: TIARA_PAGE_NAME.ISSUE_AGREEMENT,
  [ROUTES.ISSUE_KAKAO_TERMS]: TIARA_PAGE_NAME.ISSUE_KAKAO_TERMS,
  [ROUTES.ISSUE_PUBLISHER_TERMS]: TIARA_PAGE_NAME.ISSUE_PUBLISHER_TERMS,
  [ROUTES.ISSUE_MARKETING_USAGE_TERMS]: TIARA_PAGE_NAME.MARKETING_USAGE_TERMS
} as const;

export const TIARA_ISSUE_ERROR_ACTION_MAP: {[key: string]: TiaraAction} = {
  [ROUTES.DIGITAL_CARD_ERROR]: TIARA_ACTION.ISSUE_FAIL_VIEW,
  [ROUTES.INVALID_STUDENT]: TIARA_ACTION.INVALID_STUDENT_VIEW,
  [ROUTES.INVALID_CREDENTIAL_CATEGORY]: TIARA_ACTION.INVALID_CREDENTIAL_CATEGORY_VIEW,
  [ROUTES.INVALID_UNIVERSITY_DEGREE]: TIARA_ACTION.INVALID_UNIVERSITY_DEGREE_VIEW,
  [ROUTES.SIGN_FAILED]: TIARA_ACTION.SIGN_FAILED_VIEW
};

export const TIARA_COMMON_ERROR_ACTION_MAP: {[key: string]: TiaraAction} = {
  [ROUTES.SERVER_ERROR]: TIARA_ACTION.ERROR_SERVICE_VIEW,
  [ROUTES.INVALID_PHONE_NUMBER]: TIARA_ACTION.ERROR_INVALID_PHONE_NUMBER_VIEW,
  [ROUTES.INVALID_DEVICE]: TIARA_ACTION.ERROR_INVALID_DEVICE_VIEW,
  [ROUTES.INVALID_VERSION]: TIARA_ACTION.ERROR_INVALID_VERSION_VIEW,
  [ROUTES.NOT_FOUND]: TIARA_ACTION.ERROR_NOT_FOUND_VIEW,
  [ROUTES.LOGIN_ERROR]: TIARA_ACTION.ERROR_LOGIN_VIEW,
  [ROUTES.SERVICE_MAINTENANCE_ERROR]: TIARA_ACTION.ERROR_SERVICE_MAINTENANCE_VIEW,
  [ROUTES.NETWORK_ERROR]: TIARA_ACTION.ERROR_NETWORK_VIEW,
  default: TIARA_ACTION.ERROR_NOT_FOUND_VIEW
};
