import {useCallback, useEffect, useLayoutEffect} from 'react';

import {changeStatusBarColor} from '@zzng/common/scheme/digitalcard';
import {blendColor} from '@zzng/common/utils/blendColor';

import {MobileStatusBarColor} from '@/constants/statusBarColor';
import useDarkMode from '@/hooks/useDarkMode';
import {useStatusBarColorStore} from '@/store/statusBarColor';

const DIMMED_COLOR = '#00000066';

interface Props {
  color: MobileStatusBarColor | string;
  colorOnUnmount?: MobileStatusBarColor | string;
}

const recursiveBlendDimmedColor = (count: number, color: string): string => {
  if (count > 0) {
    return recursiveBlendDimmedColor(count - 1, blendColor(color, DIMMED_COLOR));
  }
  return color;
};

export const useStatusBarColor = ({color, colorOnUnmount}: Props) => {
  const {dimmedCount, setColor, color: storedColor} = useStatusBarColorStore();
  const {isDarkMode} = useDarkMode();

  const determineStatusBarColor = useCallback(
    (statusBarColor: MobileStatusBarColor | string) => {
      if (typeof statusBarColor === 'string') return statusBarColor;
      return isDarkMode ? statusBarColor.dark : statusBarColor.light;
    },
    [isDarkMode]
  );

  useEffect(() => {
    changeStatusBarColor(storedColor);
  }, [storedColor]);

  useLayoutEffect(() => {
    const statusBarColor = determineStatusBarColor(color);
    const dimmedColor = recursiveBlendDimmedColor(dimmedCount, statusBarColor);
    setColor(dimmedColor);

    return () => {
      if (colorOnUnmount) {
        const statusBarColor = determineStatusBarColor(colorOnUnmount);
        setColor(statusBarColor);
      }
    };
  }, [isDarkMode, dimmedCount]);
};
