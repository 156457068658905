import {useEffect} from 'react';

import Popup from '@/components/Popup/Layout';
import {TIARA_ACTION, TIARA_PAGE_NAME} from '@/constants/tiara';
import {useHistoryBackAOS} from '@/hooks/useHistoryBackAOS';
import useTiara from '@/hooks/useTiara';
import {usePopupStore} from '@/store/popup';

const MaintenanceUniversityListPopup = () => {
  const {trackPage, trackEvent} = useTiara({pageName: TIARA_PAGE_NAME.UNIVERSITY_LIST});

  const {closePopup} = usePopupStore();

  const onClickClose = () => {
    trackEvent({action: TIARA_ACTION.CONFIRM_CLICK});
    closePopup();
  };

  useHistoryBackAOS(onClickClose);

  useEffect(() => {
    trackPage({action: TIARA_ACTION.IN_MAINTENANCE_UNIVERSITY_VIEW});
  }, []);

  return (
    <Popup.Wrapper>
      <Popup.Title>서비스 점검 학교 안내</Popup.Title>
      <Popup.Description>
        학교 또는 증명서 발급 대행기관의 사정으로 시스템 검토 중이거나 일시 점검 중인 학교입니다. 상황에 따라 점검
        일정이 길어질 수 있습니다.
      </Popup.Description>
      <Popup.ButtonGroup>
        <Popup.Button value="확인" onClick={onClickClose} />
      </Popup.ButtonGroup>
    </Popup.Wrapper>
  );
};

export default MaintenanceUniversityListPopup;
