export const PROPOSITION = 'https://with.kakao.com/wallet/proposition';
export const CS_CENTER = 'https://cs.kakao.com/helps?service=179&category=930&locale=ko';
export const KAKAO_PRIVACY_POLICY = 'https://www.kakao.com/policy/privacy?lang=ko';
export const UNIVERSITY_UNAVAILABLE_REASON =
  'https://cs.kakao.com/helps?articleId=1073206097&service=179&category=930&locale=ko';
export const KAKAO_LOGIN = {
  local: 'https://sandbox-accounts.kakao.com/login?continue=',
  sandbox: 'https://sandbox-accounts.kakao.com/login?continue=',
  cbt: 'https://accounts.kakao.com/login?continue=',
  real: 'https://accounts.kakao.com/login?continue='
};
export const KAKAOPAY_STUDENT_MEMBERSHIP = {
  local: 'https://sandbox-fest.kakao.com/membership/university/onboarding',
  sandbox: 'https://sandbox-fest.kakao.com/membership/university/onboarding',
  cbt: 'https://fest.kakao.com/membership/university/onboarding?t_src=kakaotalk&t_ch=studentid&t_obj=membership',
  real: 'https://fest.kakao.com/membership/university/onboarding?t_src=kakaotalk&t_ch=studentid&t_obj=membership'
};
export const DIGITAL_CARD_EVENT_BENEFIT = `${EXCLUSIVE_ORIGIN}/event-benefit`;
