import {useEffect} from 'react';

import {useStatusBarColorStore} from '@/store/statusBarColor';

export const useStatusBarDimmedColor = (condition = true) => {
  const {addDimmed, removeDimmed} = useStatusBarColorStore();

  useEffect(() => {
    if (!condition) return;
    addDimmed();
    return () => {
      removeDimmed();
    };
  }, [condition]);
};
