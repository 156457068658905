import {useRef} from 'react';

import {getTracker, TiaraProp} from '@zzng/common/utils/tiara';

const useTiara = ({section = '톡학생증', pageName, pageMeta}: Partial<TiaraProp> = {}) => {
  const tracker = useRef(getTracker({section, pageName, pageMeta}));

  return tracker.current;
};

export default useTiara;
