type Props = {
  value: string;
  color?: 'gray' | 'yellow';
  onClick: () => void;
};

const Button = ({value, color = 'gray', onClick}: Props) => {
  return (
    <button type="button" className={`btn btn_${color}`} onClick={onClick}>
      {value}
    </button>
  );
};

export default Button;
