import {RefObject, useEffect, useRef} from 'react';
import cn from 'classnames';

import {isAndroid, isIOS} from '@zzng/common/utils/agent';

type Props = {
  disabled: boolean;
  inputRef: RefObject<HTMLInputElement>;
};

const FixedConfirmBtm = ({disabled, inputRef}: Props) => {
  const outerFixedBtm: HTMLDivElement | null = document.querySelector('.content-article > .fixed_btm');
  const fixedBtmRef = useRef<HTMLDivElement>(null);
  const mountedInnerHeightRef = useRef(0);
  const prevVisualViewportHeight = useRef(0);
  const bodyRef = useRef({y: 0, clientHeight: 0});

  const showOuterFixedBtms = () => {
    if (!outerFixedBtm) return;
    outerFixedBtm.style.display = 'block';
  };

  const hideOuterFixedBtms = () => {
    if (!outerFixedBtm) return;
    outerFixedBtm.style.display = 'none';
  };

  const getBottomPosition = (keyboardHeight: number) => {
    if (!fixedBtmRef.current) return 0;

    /**
     * [iOS]에서 화면을 잠궜다가 다시 열었을 때 body y값이 0으로 초기화되는 이슈
     * 최초 1번 캐싱한 값을 재사용하도록 수정
     * https://jira.daumkakao.com/browse/STUDENTID-874
     */
    if (!bodyRef.current.y || !bodyRef.current.clientHeight) {
      const {clientHeight} = document.body;
      const {y} = document.body.getBoundingClientRect();

      bodyRef.current.clientHeight = clientHeight;
      bodyRef.current.y = y;
    }

    const {clientHeight, y} = bodyRef.current;
    const style = getComputedStyle(fixedBtmRef.current);
    const paddingTop = parseInt(style.paddingTop);
    const paddingBottom = parseInt(style.paddingBottom);
    const viewportHeight = clientHeight - keyboardHeight;
    const currentHeight = clientHeight - viewportHeight;

    return currentHeight + (y - (paddingBottom + paddingTop));
  };

  const showConfirmButton = () => {
    fixedBtmRef.current?.classList.remove('hide');
  };

  const onResize = () => {
    const visualViewportHeight = window.visualViewport?.height || 0;

    if (prevVisualViewportHeight.current === 0) {
      prevVisualViewportHeight.current = visualViewportHeight;
    }

    /**
     * 안드로이드의 경우 키보드 숨기기 버튼이 있는데,
     * 키보드를 숨길 경우 focus, blur 이벤트로는 감지가 불가능하여 키보드를 숨길 경우 '확인' 버튼이 그대로 노출되는 이슈가 있음
     * 그래서 viewport 사이즈가 변경될 때마다 체크하여 키보드 노출 유무에 따라 기존 fixed_btm 버튼들 노출 유무를 정하는 코드를 추가함
     * 키보드 숨기기란? https://t1.kakaocdn.net/zzng_static/digital-card/mock/android_hide_keyboard.jpeg
     */
    if (isAndroid) {
      const isHideKeyboard = prevVisualViewportHeight.current >= visualViewportHeight;
      isHideKeyboard ? hideOuterFixedBtms() : showOuterFixedBtms();
    }

    prevVisualViewportHeight.current = visualViewportHeight;
  };

  useEffect(() => {
    hideOuterFixedBtms();
    mountedInnerHeightRef.current = window.innerHeight;

    visualViewport?.addEventListener('resize', onResize);
    window.handleMobileKeyboardStatus = (_, keyboardHeight) => {
      if (!inputRef.current || !fixedBtmRef.current) return;

      if (isIOS) {
        const bottom = getBottomPosition(keyboardHeight);
        fixedBtmRef.current.style.bottom = `${bottom}px`;
      }

      showConfirmButton();
    };

    return () => {
      showOuterFixedBtms();
      visualViewport?.removeEventListener('resize', onResize);
      window.handleMobileKeyboardStatus = () => {};
    };
  }, []);

  return (
    <div ref={fixedBtmRef} className="fixed_btm hide">
      <div className="group_btn">
        <button type="button" className={cn('btn btn_yellow', {disabled})} disabled={disabled}>
          확인
        </button>
      </div>
    </div>
  );
};

export default FixedConfirmBtm;
