import {useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';

import IssueProgressPopup from '@/components/Popup/Type/IssueProgressPopup';
import IssueStopPopup from '@/components/Popup/Type/IssueStopPopup';
import MaintenanceUniversityListPopup from '@/components/Popup/Type/MaintenanceUniversityListPopup';
import MaintenanceUniversityPopup from '@/components/Popup/Type/MaintenanceUniversityPopup';
import {useStatusBarDimmedColor} from '@/hooks/useStatusBarDimmedColor';
import {PopupType, usePopupStore} from '@/store/popup';

const Popup = () => {
  const {isOpen, closePopup, type} = usePopupStore();
  const location = useLocation();

  useStatusBarDimmedColor(isOpen);

  useLayoutEffect(() => {
    closePopup();
  }, [location]);

  if (!isOpen) return null;

  switch (type) {
    case PopupType.PROGRESSING:
      return <IssueProgressPopup />;
    case PopupType.STOP:
      return <IssueStopPopup />;
    case PopupType.MAINTENANCE:
      return <MaintenanceUniversityPopup />;
    case PopupType.MAINTENANCE_LIST:
      return <MaintenanceUniversityListPopup />;
    default:
      return <></>;
  }
};

export default Popup;
